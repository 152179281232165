import React, { useMemo, useEffect, useRef, useState} from 'react';
import _ from 'loadsh'
import useScrollBlock from '../../Hooks/useScrollBlock';
import classes from "./ClientSection.module.css"
import {db} from '../../firebase'
import arrow from "../../Assets/ImageAssets/Section3/ICONS/arrows.svg"
import {collection, query, getDoc, onSnapshot, getDocs} from "firebase/firestore"


const ClientSection = (props) => {

    const scrollContainer = useRef();
    const [blockScroll, allowScroll] = useScrollBlock();
    const [clientList , setClientList] = useState([]);
    const [showEmptyClient, setShowEmptyClient] = useState(true)
    useEffect(() => {
        const q = query(collection(db,'our clients'))
        getDocs(q).then((querySnapshot)=>{
            console.log(querySnapshot.docs[0].data().index)
        console.log(querySnapshot.docs[1].data()['index'])
                setClientList(querySnapshot.docs.sort((a,b)=>{
                    return a.data()['index'] - b.data()['index'];
                }));
                setShowEmptyClient(false)
            });
    },[])




    const changePage = (direction) => {
        
        if (direction > 0) {
        
          scrollContainer.current.scrollLeft += window.innerWidth
        } else {
        //  
          scrollContainer.current.scrollLeft -= window.innerWidth
        }
      };
      
    const onWheelThrottled = useMemo(() =>{
        const onWheel = (event) => {
           
            changePage(event.deltaY);
          };
        return _.throttle(onWheel, 1000)
        }, []);

    const mouseOnClient = () => {
       
        if (props.isMobile) {
            blockScroll();
            props.navWidthHandler(true);//This line prevents navbbar stretching to 100vw after removal of Scollbar
        }


    }
    
  

    const leftArrowClickHandler = ()=>{
        scrollContainer.current.scrollLeft -= window.innerWidth;
    }
    const rightArrowClickHandler = ()=>{
        scrollContainer.current.scrollLeft += window.innerWidth
    }
    
       
    

    return (
        <>

            <div className={classes.bodyForClient} id="clients" >
                <div className={classes.title}><div><span>Our</span> Clients</div></div>
                <div className={classes.containerForClient}  onWheel={onWheelThrottled}  ref={scrollContainer} onMouseEnter={mouseOnClient} onMouseLeave={() => { allowScroll() }} >
                    <div className={classes.arrow1} onClick={leftArrowClickHandler}>
                        <img src={arrow} alt=""></img>
                    </div>
                    <div className={classes.arrow2} onClick={rightArrowClickHandler}>
                        <img src={arrow} alt=""></img>
                    </div>
                    {
                        showEmptyClient &&
                                < >
                                <div key="1" className={classes.contentForClient}/>
                                <div key="2" className={classes.contentForClient}/>
                                <div key="3" className={classes.contentForClient}/>
                                </>
                    }
                  
                    {

                        clientList.map((e)=>{
                            return(
                                <div key={e.data().documentName} className={classes.contentForClient}>
                                    <img src={e.data().logoImageUrl} alt=" "></img>
                                </div>
                            )
                        })
                    }




                </div>
                
          </div>

        </>
    )
}
export default ClientSection