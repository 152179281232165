import React, { useState, useEffect, useRef} from 'react';
import TagManager from 'react-gtm-module';
import Landing from './Pages/Landing';
import "./App.css"
import Navbar from './Components/Navbar/Navbar';
import { Route, Routes } from 'react-router-dom'
import Footer from './Components/Footer/Footer';
import About from './Pages/About';
import TeamPrakriya from './Pages/TeamPrakriya';
import InfluencerRegistration from './Pages/InfluencerRegistration';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import {app} from './firebase';
import { getAuth, signInAnonymously} from "firebase/auth";

const App = () => {


  const[navWidth,setNavWidth]=useState(false);
  const[transparent,setTransparent] = useState(false);
  const navWidthHandler=(bool)=>{
    setNavWidth(bool);
  }
  const transparentNav = (bool)=>{
    setTransparent(bool)
  }

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth).then(()=>{
      console.log('.');
    })
  },[])

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-WGJKKG7' });
    }, []);

  return (
    <>
      <Navbar navWidth={navWidth}  transparent={transparent}/>    
            <Routes>
              <Route exact path="/" element={<Landing navWidthHandler={navWidthHandler} transparentNav={transparentNav}/>} ></Route>
              <Route path="/about" element={<About></About>}></Route>
              <Route path="/teamPrakriya" element={<TeamPrakriya></TeamPrakriya>}></Route>
              <Route path="/InfluencerRegistration" element={<InfluencerRegistration></InfluencerRegistration>}></Route>
              <Route path="/privacyPolicy" element={<PrivacyPolicy></PrivacyPolicy>}></Route>
            </Routes>
      <Footer/>
    </>
  );
}

export default App;
